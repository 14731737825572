import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Foot from "../components/foot"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import AiraTestimonalImg from "../images/testimonials/aira-testimonial.jpg"
import LucienTestimonialImg from "../images/testimonials/lucien-testimonial.jpg"
import BettyFlowersImg from "../images/testimonials/betty-flowers.jpg"
import ScottFlowersImg from "../images/testimonials/scott-flowers.jpg"

const ClientTestimonials = () => (
  <Layout>
    <SEO title="Client Testimonials" />
    <Helmet>
      <meta property="og:image" content={ AiraTestimonalImg } />
    </Helmet>
    <Container className="mt-4">
      <h3>Client Testimonials</h3>
      <div style={{fontSize: "12pt"}}>
        <p className="mt-4 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>Grace Home has been an exceptional find for our family. Grace has been an extension of home, 
          with attentive care and support that made the complex burdens manageable. We learned that Jennifer had built 
          Grace Home to care for her parents. This high standard shows in the quality and details of the home and 
          Jennifer's thoughtful approach. In our frequent visits prior to pandemic, Grace Home was always a warm 
          welcome.<br /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;Jennifer and her staff have provided not only care but support and advocacy for my 
          father’s well-being. Jennifer runs Grace Home with great personal commitment. This commitment to care and 
          advocacy has been more than we would have hoped for and much more than we’d experienced elsewhere. This 
          includes attention to health and nutrition and the safety of the residents as individuals. Frequently 
          participating in calls with my fathers physicians, Jennifer often highlighted with me where she saw issues 
          with treatment and how we might find better care for my father from our providers. Jennifer was thoughtful to 
          adjust menus to accommodate my father’s favorite foods, celebrate holidays, and meet the evolving needs. Fresh 
          fruit, healthy salads, freshly prepared foods at every meal… my father hasn’t had such a healthy diet in 
          years, and it shows in his overall health. Prior to pandemic, we would visit frequently, enjoying time both 
          inside and in the yard with our family. While the pandemic has been a huge disruption to life and a 
          significant disruption to healthcare and visitations, Jennifer again stepped up and has been very thoughtful. 
          She and her staff encouraged my father and actively supported his continuation of the exercises prescribed. 
          Jennifer finds creative ways to visit, even if virtual.<br /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;Jennifer has provided thoughtful care in all aspects for my father. Grace Home itself 
          is an extension of this. I would not hesitate to recommend Grace Home. The level of attention and caring shown 
          to my father has brought comfort and confidence beyond our expectations.<b>&rdquo;</b>
        </p> 
        <p className="text-end">
          <b><i>Scott G.</i></b>
        </p>
      </div>
      <Row className="mt-4">
        <Col xs={12} md className="mb-3">
          <img 
            src={AiraTestimonalImg} alt=""
            style={{borderRadius: "3px", boxShadow: "0 0 5px 2px #60b7a4"}} 
          />
        </Col>
        <Col className="mb-3">
          <img 
            src={LucienTestimonialImg} alt=""
            style={{borderRadius: "3px", boxShadow: "0 0 5px 2px #60b7a4"}} 
          />
        </Col>
      </Row>
      <div style={{fontSize: "12pt"}}>
        <p className="mt-2 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>From the bottom of my heart, on behalf of my family, I cannot express my gratitude for your 
          compassionate and loving treatment of my aunt. I am humbled by all of you.<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Warmly, Maureen A.</i></b>
        </p>

        <p className="mt-5 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>Your vision of service to others and honor to your parents is so noble and pure, I hope it is 
          fulfilled quickly. Thank you for your time and attention to our family.<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Harolyn E.</i></b>
        </p>

        <p className="mt-5 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>My mother moved to Grace Home Athena on April 1, just as the COVID shutdown started. It was a 
          challenge for me to not be able to help her settle-in or follow-up with visits to gauge how well she was 
          adjusting. I was reassured by knowing that I had chosen Jennifer’s home because I trust her and her staff.  
          Jennifer and her residence were recommended by an elder care professional in the Valley who I’ve worked with 
          for three years and highly respect. My mother’s hospice nurses and social workers have also given praise for 
          the care that Jennifer provides.<br /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;For someone who can no longer be cared for at home, there is no better place for 
          elder care than a well-run RCFE. Grace Home Athena is beautiful, clean, spacious, comfortable and includes 
          improvements to ensure safe care of the residents. Jennifer and her staff are caring and compassionate, 
          providing assistance with daily, personal, and hygiene care. In the home setting they’re able to adjust to 
          personal preferences regarding meals and socializing. In this challenging time, Jennifer keeps me updated on 
          my mom’s physical and mental state. There’s no better gift than to know that my mom is receiving excellent 
          care and not be burdened with the daily concern that can come.<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Martha J.</i></b>
        </p>

        <Row className="mt-5">
          <Col xs={12} md className="mb-3">
            <img 
              src={BettyFlowersImg} alt=""
              style={{borderRadius: "3px", boxShadow: "0 0 5px 2px #60b7a4"}} 
            />
          </Col>
          <Col className="mb-3">
            <img 
              src={ScottFlowersImg} alt=""
              style={{borderRadius: "3px", boxShadow: "0 0 5px 2px #60b7a4"}} 
            />
          </Col>
        </Row>

        <p className="mt-2 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>Grace Home Athena and Jennifer are a great blessing in our life. My husband's elderly mom 
          sadly has Alzheimer's. We had been taking care of her in our home for three years but she became too much for 
          us to handle. We were referred to Jennifer by a trusted source. I immediately liked Jennifer &mdash; very 
          honest and caring. She agreed to take mom and we are very grateful. We know she is in a happy, caring and safe 
          environment. Thank you for what you do Jennifer and all your staff at Grace Home Athena.<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Joanne C.</i></b>
        </p>

        <p className="mt-5 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>Thank you, Jennifer, for your thoughtfulness and kindess during our time of grief. You have a
          very wonderful caregiver. I loved her caring for my sister.<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Fondly, Toni & Michele L.</i></b>
        </p>

        <p className="mt-5 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>When it came time for our family to find a care home for my great aunt it was not an easy task, 
          but we were so thankful when we discovered Grace Home Athena. Jennifer’s kind heart and genuine concern for 
          the well being of my great aunt was evident from the very first moment we spoke. She patiently worked with us 
          to facilitate my great aunt’s move during a pandemic, gave our family detailed updates often, responded to 
          all of our concerns with compassion, and sent heartwarming pictures of my great aunt. Due to COVID 
          restrictions, we were unable to be there in person to help my great aunt transition into her new home. But 
          Jennifer went above and beyond to ensure my great aunt felt safe and loved. Knowing that our loved one is 
          well cared for and part of a loving home provides so much comfort to our family during this difficult phase 
          of my great aunt’s life. We are so thankful for Jennifer and all of the caretakers at Grace Home!<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Lavangela D.</i></b>
        </p>

        <p className="mt-5 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>It's a significant decision and step to relocate a parent or loved one into a new living space 
          and environment. Thanks to Grace Home Athena, our experience has been smooth and reassuring as a first class, 
          professional experience. We have been extremely impressed and happy with the facility, the communication, and 
          the terrific care! It is a comfortable feeling for the resident as well as us who love and care knowing that 
          our loved ones are in great hands with the staff and leadership at Grace Home Athena!<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Jeff T.</i></b>
        </p>

        <p className="mt-5 mb-1" style={{textAlign: "justify"}}>
          <b>&ldquo;</b>I have sent many families to Grace Home Athena, and the feedback I receive is nothing short of 
          outstanding, and all of the families are extremely grateful. Jennifer and her staff make a difficult time a 
          little less complex and a whole lot more loving. They truly love and care for their residents. It's a very 
          special place. It's always a blessing to me to be able to introduce a family to Jennifer and her lovely 
          home.<b>&rdquo;</b>
        </p>
        <p className="text-end">
          <b><i>Rebecca P.</i></b><br />
          <i>Director of Care, Local Placement Agency</i>
        </p>
      </div>

      <div style={{marginTop: "125px"}}>
        <Foot />
      </div>
      
    </Container>
  </Layout>
)

export default ClientTestimonials